
  export default {
    name: `AeqCamera`,
    props: {
      audio: {
        type: Boolean,
        default: false
      },
      video: {
        type: [Boolean, Object],
        default() {
          return {
            width: { min: 640, ideal: 1280 },
            height: { min: 480, ideal: 720 },
            advanced: [{ width: 1920, height: 1280 }, { aspectRatio: 1.333 }]
          }
        }
      },
      pushPicture: {
        type: Function,
        default: () => {}
      },
      iconSize: {
        type: Number,
        default: null
      }
    },
    data() {
      return {
        dialogCamera: false,
        stream: null,
        picture: null,
        inputDisabled: true,
        videoDevices: [],
        currentDevicesID: null,
        indexCamera: null
      }
    },
    watch: {
      async dialogCamera(newValue, oldValue) {
        if (newValue === oldValue) return
        if (!!newValue) {
          await this.startCamera()
          this.startStream()
        } else {
          await this.closeDialog()
        }
      }
    },
    async destroyed() {
      await this.closeDialog()
    },
    async created() {
      await this.initDevices()
    },
    methods: {
      async initDevices() {
        try {
          if (!navigator.mediaDevices?.enumerateDevices) {
            throw new Error(`unable to initialise the camera`)
          } else {
            // List cameras and microphones.
            const devices = await navigator.mediaDevices.enumerateDevices()

            this.videoDevices = devices.filter((device) => device.kind === `videoinput`)

            if (this.videoDevices.length >= 1) {
              this.indexCamera = 0
              this.currentDevicesID = this.videoDevices[this.indexCamera].deviceId
            }
            if (this.videoDevices.length < 1) {
              throw new Error(`unable to initialise the camera`)
            }
          }
        } catch (e) {
          this.$flashMessage.error({
            title: this.$i18n.t(`messages.error`),
            message: this.$i18n.t(`messages.camera_initialization_failed`)
          })
          this.initialize()
        }
      },
      initialize() {
        this.dialogCamera = false
        this.stream = null
        this.picture = null
        this.inputDisabled = true
        this.videoDevices = []
        this.currentDevicesID = null
      },
      async startCamera() {
        if (!!this.stream) {
          await this.stopCamera()
        }
        const constraints = {
          video: { ...this.video, deviceId: this.currentDevicesID }
        }
        try {
          this.stream = await navigator.mediaDevices.getUserMedia(constraints)
        } catch (e) {
          this.$flashMessage.error({
            title: this.$i18n.t(`messages.error`),
            message: this.$i18n.t(`messages.camera_initialization_failed`)
          })
        }
      },
      async toggleCameraType() {
        this.indexCamera = this.videoDevices.findIndex((device) => device.deviceId === this.currentDevicesID)
        if (this.indexCamera === -1) {
          this.currentDevicesID = this.videoDevices[0].deviceId
        } else {
          this.currentDevicesID = this.videoDevices[this.indexCamera + 1]?.deviceId || this.videoDevices[0].deviceId
        }
        this.stopCamera()
        await this.startCamera()
        this.startStream()
      },
      stopCamera() {
        if (!!this.stream) {
          this.stream.getTracks().forEach((track) => track.stop())
          this.stream = null
        }
      },
      getPicture() {
        this.picture = this.$helpers.createImageFromSrcToCanvas({
          src: this.$refs[`aeq-camera-video`],
          canvas: this.$refs[`aeq-camera-canvas`]
        })
      },
      async closeDialog() {
        if (!!this.stream) {
          await this.stopCamera()
        }
        this.initialize()
      },
      startStream() {
        this.$nextTick(() => {
          if (this?.$refs[`aeq-camera-video`]) {
            this.$refs[`aeq-camera-video`].srcObject = this.stream
            this.$refs[`aeq-camera-video`].play()
            this.inputDisabled = false
          } else {
            this.startStream()
          }
        })
      },
      retry() {
        this.picture = null
        this.startStream()
      },
      generateFileName() {
        const date = new Date().toLocaleString()
        const dateWithoutSpecialChars = date.replace(/[.:]/g, `-`).replace(/[,]/g, ``).replace(/ +/g, `_`)
        return `photo_capture_${dateWithoutSpecialChars}.jpg`
      },
      async pushPictureAndClose() {
        const file = await this.$helpers.b64ToFile(this.picture, this.generateFileName())
        this.pushPicture(file)
        this.dialogCamera = false
      }
    }
  }
