
  export default {
    name: `AeqDocumentPage`,
    props: {
      token: {
        type: String,
        default: null
      },
      value: {
        type: Array,
        default: () => []
      },
      readonly: {
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
        hint: this.$attrs.hint,
        innerValue: this.value
      }
    },
    mounted() {},
    methods: {
      getIcon(doc) {
        const type = !!doc.contentType ? doc.contentType : `file`

        // PDF
        if (type.includes(`pdf`)) {
          return `mdi-file-pdf-box mdi-file-pdf`
        }

        // IMAGE
        if (type.includes(`image`)) {
          return `mdi-file-image`
        }

        // MS WORD
        if (type.includes(`msword`) || type.includes(`wordprocessingml`)) {
          return `mdi-microsoft-word`
        }

        // MS EXCEL
        if (type.includes(`csv`) || type.includes(`spreadsheetml`)) {
          return `mdi-microsoft-excel`
        }

        return `mdi-file`
      },
      openDocument(doc) {
        console.log(`openDocument`)
        if (!!doc.data) {
          this.$helpers.FileSaver.saveAs(new Blob(this.$helpers.b64ToArrayBuffer(doc.data)), doc.fileName)
        } else if (!!doc.downloadLink) {
          const getDocument = !!this.token
            ? (d) => this.$api.external.getOneDocument(d)
            : (d) => this.$api.documents.getOne(d)
          getDocument(doc.downloadLink)
            .then((res) => {
              const data = res?.data || res?._data
              if (data) {
                this.$helpers.FileSaver.saveAs(new Blob([data]), doc.fileName)
              }
            })
            .catch(() => {
              this.$flashMessage.error({
                title: `<${this.$options.name}> : ${this.$t(`messages.error`)}`,
                message: this.$t(`messages.cant_get_document`)
              })
            })
        }
        // eslint-disable-next-line vue/custom-event-name-casing
        this.$emit(`click:open`, doc)
      },
      deleteDocument(doc) {
        // eslint-disable-next-line vue/custom-event-name-casing
        this.$emit(`click:delete`, doc)
      }
    }
  }
